import useTranslation from 'next-translate/useTranslation';
import { ModalsKey } from '@/enums/modalsKey';
import styles from './styles.module.scss';
import { useUserAccount } from '@/api/user/queries';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { useRouter } from 'next/router';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import Color from 'color';
import { getComputedColor } from '@package/utils';

export default function ReferSection() {
  const { t } = useTranslation('common');
  const { isAuthorized } = useUserAccount();
  const { navigateToModal } = useModalNavigation();
  const { push } = useRouter();

  const { isV2LayoutVariant, isCollapsed, layoutVariant } = useLayoutSettings();

  function handleNavigate() {
    if (!isAuthorized) {
      navigateToModal(ModalsKey.Login);
      return;
    }

    push(isAuthorized ? '/affiliates' : '/affiliates/panel');
  }

  return (
    <div
      className={`${styles.container} ${isCollapsed && isV2LayoutVariant ? styles['container--v2'] : ''} ${layoutVariant === SettingLayoutComponentId.LayoutV3 ? styles['container--v3'] : ''}`}
      onClick={handleNavigate}
      style={{
        //@ts-ignore
        '--primary-alpha':
          layoutVariant == SettingLayoutComponentId.LayoutV3
            ? Color(getComputedColor('--primary-color')).alpha(0.1).toString()
            : undefined,
      }}
    >
      <div className={styles.container__ticket}>
        <div className={styles.container__ticket__icon}>
          <i className="fa-solid fa-gift fa-swap-opacity" />
        </div>

        <div className={styles.container__row}>
          <span className={styles.container__ticket__label}>
            {t('refer_and_win')}
          </span>
        </div>
      </div>
    </div>
  );
}
