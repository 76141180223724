import SidebarNotCollapsedItemCollapsePage from './Page';
import { SidebarNotCollapsedItemCollapsePagesProps } from './props';
import styles from './styles.module.scss';
import { useMemo } from 'react';

export default function SidebarNotCollapsedItemCollapsePages({
  pages,
}: SidebarNotCollapsedItemCollapsePagesProps) {
  const Pages: JSX.Element[] = useMemo(
    () =>
      pages.map(page => (
        <SidebarNotCollapsedItemCollapsePage
          page={page}
          key={`sidebar_not_collapsed_item_page_${page.label}`}
        />
      )),
    [pages],
  );
  return <div className={styles.container}>{Pages}</div>;
}
