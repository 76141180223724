import { ItemProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import Collapse from '@mui/material/Collapse';
import { useToggle } from '@starsoft/common/hooks';
import { platformAssets } from '@/utils/assets';
import styles from './styles.module.scss';
import SidebarNotCollapsedItemSection from './Section';
import SidebarNotCollapsedItemCollapsePages from './CollapsePages';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';

export default function SidebarNotCollapsedItem({ section }: ItemProps) {
  const { t } = useTranslation('common');
  const { layoutVariant } = useLayoutSettings();
  const [open, toggle] = useToggle(false);

  return (
    <div className={styles.container}>
      <SidebarNotCollapsedItemSection
        label={t(section.label, {
          provider: platformAssets.app,
          app_provider: platformAssets.app,
        })}
        icon={section.icon}
        isOpen={open}
        onClick={toggle}
        shouldShowChevron
      />

      <Collapse in={open}>
        <div
          className={`${styles.container__collapse} ${layoutVariant === SettingLayoutComponentId.LayoutV3 ? styles['container__collapse--v3'] : ''}`}
        >
          <SidebarNotCollapsedItemCollapsePages pages={section.pages} />
        </div>
      </Collapse>
    </div>
  );
}
